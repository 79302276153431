import React from 'react';

const NetworkModel = () => {
  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white px-6 py-3 rounded-md">
      Your network is offline. Check your internet connection and refresh the page.
    </div>
  );
};

export default NetworkModel;